import React from 'react';
import OrdersTable from './OrdersTable';

function App() {
  return (
    <div className="App">
      <h1>Orders from Mercado Libre</h1>
      <OrdersTable />
    </div>
  );
}

export default App;
