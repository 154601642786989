import React, { useState, useEffect } from 'react';
import axios from 'axios';

const OrdersTable = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await axios.get('https://api.mercadolibre.com/orders/search?seller=480473418', {
          headers: {
            Authorization: 'Bearer APP_USR-5372691677113751-122608-b078bb6d0f5fbe8a44b84ee1950bb163-480473418',
            'x-format-new': '',
          },
        });
        setOrders(response.data.results);
      } catch (err) {
        setError('Error fetching orders.');
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <table border="1">
      <thead>
        <tr>
          <th>Order ID</th>
          <th>Buyer Nickname</th>
          <th>Buyer ID</th>
          <th>Total Amount</th>
          <th>Order Status</th>
          <th>Status Detail</th>
          <th>Item ID</th>
          <th>Item Title</th>
          <th>Seller SKU</th>
          <th>Quantity</th>
          <th>Unit Price</th>
          <th>Full Unit Price</th>
          <th>Currency</th>
          <th>Condition</th>
          <th>Tax ID</th> {/* Nueva columna */}
          <th>Tax Amount</th> {/* Nueva columna */}
          <th>Tax Currency</th> {/* Nueva columna */}
          <th>Payment Status</th>
          <th>Payment Method</th>
          <th>Shipping Status</th>
          <th>Invoice</th>
        </tr>
      </thead>
      <tbody>
        {orders.map(order => {
          const item = order.order_items[0]?.item || {};
          const taxes = order.taxes || {};
          return (
            <tr key={order.id}>
              <td>{order.id}</td>
              <td>{order.buyer.nickname}</td>
              <td>{order.buyer.id}</td>
              <td>{order.total_amount} {order.currency_id}</td>
              <td>{order.status}</td>
              <td>{order.cancel_detail?.description || 'No Details'}</td>
              <td>{item.id || 'N/A'}</td>
              <td>{item.title || 'N/A'}</td>
              <td>{item.seller_sku || 'N/A'}</td>
              <td>{order.order_items[0]?.quantity || 'N/A'}</td>
              <td>{order.order_items[0]?.unit_price || 'N/A'}</td>
              <td>{order.order_items[0]?.full_unit_price || 'N/A'}</td>
              <td>{order.order_items[0]?.currency_id || 'N/A'}</td>
              <td>{item.condition || 'N/A'}</td>
              <td>{taxes.id || 'N/A'}</td> {/* Tax ID */}
              <td>{taxes.amount || 'N/A'}</td> {/* Tax Amount */}
              <td>{taxes.currency_id || 'N/A'}</td> {/* Tax Currency */}
              <td>{order.payments[0]?.status || 'N/A'}</td>
              <td>{order.payments[0]?.payment_method_id || 'N/A'}</td>
              <td>{order.tags.includes('no_shipping') ? 'No Shipping' : 'Shipped'}</td>
              <td>
                {order.status === 'cancelled'
                  ? 'Order Cancelled'
                  : `Invoice for ${order.buyer.nickname}`}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default OrdersTable;
